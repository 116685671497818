var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "app-simple-dialog",
    {
      attrs: {
        value: _vm.gasPriceModal,
        width: "420",
        title: "Select transaction fee",
      },
      on: { close: _vm.handleClose },
    },
    [
      _c("settings-gas-price", {
        attrs: {
          "is-swap": true,
          buttons: _vm.gasButtons,
          "set-selected": _vm.setGas,
          "gas-price": _vm.gasPrice,
          "cost-in-eth": _vm.costInEth,
          "tx-fee-formatted": _vm.txFeeFormatted,
          "tx-fee-usd": _vm.txFeeUsd,
          "not-enough-eth": _vm.notEnoughEth,
          "total-gas-limit": _vm.totalGasLimit,
          "close-dialog": _vm.closeDialog,
          "from-settings": false,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }