var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        "content-class": "whitePopup",
        width: _vm.width,
        "max-width": _vm.maxWidth,
        fullscreen: _vm.$vuetify.breakpoint.smAndDown,
      },
      model: {
        value: _vm.isDialogOpen,
        callback: function ($$v) {
          _vm.isDialogOpen = $$v
        },
        expression: "isDialogOpen",
      },
    },
    [
      !_vm.noPadding
        ? _c(
            "div",
            { class: _vm.$vuetify.breakpoint.smAndDown ? "pa-3" : "pa-8 pt-5" },
            [
              !_vm.noTitle
                ? _c(
                    "div",
                    {
                      class: [
                        _vm.title ? "mb-7" : "",
                        "d-flex align-start justify-space-between",
                      ],
                    },
                    [
                      _c("div", { staticClass: "mew-heading-2 pr-5" }, [
                        _vm._v(" " + _vm._s(_vm.title) + " "),
                      ]),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "textLight", icon: "" },
                          on: { click: _vm.closeDialog },
                        },
                        [_c("v-icon", [_vm._v("mdi-close")])],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._t("default"),
            ],
            2
          )
        : _c(
            "div",
            { staticClass: "position-relative" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "close-button",
                  attrs: { fab: "", color: "transparent", depressed: "" },
                  on: {
                    click: function ($event) {
                      _vm.isDialogOpen = false
                    },
                  },
                },
                [
                  _c("v-icon", { attrs: { color: "white" } }, [
                    _vm._v("mdi-close"),
                  ]),
                ],
                1
              ),
              _vm._t("default"),
            ],
            2
          ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }